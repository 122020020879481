var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-12"},[_c('p',{style:(_vm.titleStyles)},[_vm._v("Top treatment for you")])]),_vm._l((_vm.getServices),function(product,index){return _c('div',{key:index,staticClass:"col-12 col-sm-6 col-md-6 col-lg-4 mb-3"},[_c('div',{staticClass:"card shadow-sm d-flex flex-column h-100 mx-auto position-relative",style:(_vm.getCardBackground(_vm.ProductData.style) +
                                _vm.getCardBorderCSS(_vm.ProductData.style) +
                                _vm.getPagePadding(_vm.ProductData.style) +
                                _vm.getFontFamily(_vm.ProductData.style) +
                                _vm.ListProductMaxWidth(
                                  _vm.isDesktopView
                                    ? _vm.ProductData.productsPerPage
                                    : _vm.ProductData.productsPerPageMobile
                                ))},[_c('div',[(_vm.getProductInfoDetail(_vm.ProductData.useProductVariant, product,'image'))?_c('div',{staticStyle:{"height":"100%"}},[(
                                      _vm.checkFileType(_vm.getProductInfoDetail(_vm.ProductData.useProductVariant, product,'image')) == 'video'
                                    )?_c('div',{style:(_vm.ListImageAspectRatio(_vm.ProductData.style))},[_c('video',{ref:"videoRef",refInFor:true,staticStyle:{"position":"absolute","top":"0","left":"0","bottom":"0","right":"0","height":"100%","width":"100%"},style:(_vm.getImageFit(_vm.ProductData.style) +
                                        _vm.getImageTopBorderCSs(_vm.ProductData.style)),attrs:{"src":`${_vm.getProductInfoDetail(_vm.ProductData.useProductVariant, product,'image')}`,"controls":""}})]):_c('div',{style:(_vm.ListImageAspectRatio(_vm.ProductData.style))},[(_vm.getProductInfoDetail(_vm.ProductData.useProductVariant, product,'image').startsWith('upload'))?_c('LazyImage',{staticStyle:{"position":"absolute","top":"0","left":"0","bottom":"0","right":"0","height":"100%","width":"100%"},style:(_vm.getImageFit(_vm.ProductData.style) +
                                        _vm.getImageTopBorderCSs(_vm.ProductData.style)),attrs:{"src":'/' + _vm.getProductInfoDetail(_vm.ProductData.useProductVariant, product,'image'),"alt":""}}):_c('LazyImage',{staticStyle:{"position":"absolute","top":"0","left":"0","bottom":"0","right":"0","height":"100%","width":"100%"},style:(_vm.getImageFit(_vm.ProductData.style) +
                                        _vm.getImageTopBorderCSs(_vm.ProductData.style)),attrs:{"src":_vm.getProductInfoDetail(_vm.ProductData.useProductVariant, product,'image'),"alt":""}})],1)]):_c('LazyImage',{staticStyle:{"height":"100%","width":"100%"},style:(_vm.getImageTopBorderCSs(_vm.ProductData.style)),attrs:{"src":'/images/image_default_placeholder.png',"alt":""}})],1),_c('div',{staticClass:"mt-2 flex-grow-1",style:(_vm.getPaddingCss(_vm.ProductData.style))},[_c('div',{staticClass:"d-flex flex-column h-100"},[_c('div',{staticClass:"px-3"},[_c('p',{style:(_vm.getTitleStyles(_vm.ProductData.style))},[_vm._v(" "+_vm._s(_vm.getProductInfoDetail(_vm.ProductData.useProductVariant, product,'title'))+" ")]),(
                                        product.rating && _vm.isJudgeMeInstalled
                                      )?_c('div',{staticClass:"Review px-3",style:(_vm.getPriceColor(_vm.ProductData.style)),domProps:{"innerHTML":_vm._s(product.rating)}}):_vm._e(),_c('div',{staticClass:"d-flex align-items-center",style:('justify-content:' +
                                        _vm.ProductData.style.textAlign +
                                        ';')},[(product.price && !_vm.isHidePrice)?_c('p',{staticClass:"mr-2",style:(_vm.getPriceColor(_vm.ProductData.style))},[(
                                            _vm.CurrencySign &&
                                            _vm.currencyPos == 'left'
                                          )?_c('span',{class:_vm.currencySpace ? 'mr-1' : null},[_vm._v(_vm._s(_vm.CurrencySign))]):_vm._e(),_vm._v(_vm._s(_vm.getProductInfoDetail(_vm.ProductData.useProductVariant, product,'price'))),(
                                            _vm.CurrencySign &&
                                            _vm.currencyPos == 'right'
                                          )?_c('span',{class:_vm.currencySpace ? 'ml-1' : null},[_vm._v(_vm._s(_vm.CurrencySign))]):_vm._e()]):_vm._e(),(
                                          product.compare_at_price &&
                                          product.price !==
                                            product.compare_at_price &&
                                          !_vm.isHidePrice
                                        )?_c('p',{style:(_vm.getPriceColor(_vm.ProductData.style))},[_c('s',[(
                                              _vm.CurrencySign &&
                                              _vm.currencyPos == 'left'
                                            )?_c('span',{class:_vm.currencySpace ? 'mr-1' : null},[_vm._v(_vm._s(_vm.CurrencySign))]):_vm._e(),_vm._v(_vm._s(product.compare_at_price)),(
                                              _vm.CurrencySign &&
                                              _vm.currencyPos == 'right'
                                            )?_c('span',{class:_vm.currencySpace ? 'ml-1' : null},[_vm._v(_vm._s(_vm.CurrencySign))]):_vm._e()])]):_vm._e()])]),_c('div',{staticClass:"d-flex flex-column justify-content-between flex-grow-1"},[_c('div',{},[(
                                          !_vm.ProductData.hideDescription &&
                                          !_vm.ProductData.showHeirarchyDesciption
                                        )?_c('div',[_c('div',{staticClass:"d-flex align-items-center mt-1",style:(_vm.limitProductDescription(
                                              _vm.ProductData.limitDescription,
                                              product.readMore
                                            ))},[(_vm.ProductData.useBulletDescription && product.bullet_description
 && product.bullet_description.length
)?_c('div',_vm._l((product.bullet_description),function(bulletPoint,index2){return _c('div',{key:index2},[_c('div',{staticClass:"d-flex align-items-start mt-1",style:(_vm.getBulletStyles(_vm.ProductData.style))},[_c('div',{staticClass:"mr-3"},[_c('i',{staticClass:"fa",class:_vm.ProductData.selectedBulletIcon})]),_c('span',{staticStyle:{"text-align":"start"}},[_vm._v(_vm._s(bulletPoint))])])])}),0):_c('div',{staticClass:"w-100",domProps:{"innerHTML":_vm._s(product.description)}})]),(
                                            _vm.ProductData.limitDescription &&
                                            product.description &&
                                            product.description
                                              .split(' ')
                                              .join('').length > 150
                                          )?_c('div',{},[_c('button',{staticClass:"btn",class:product.readMore ? 'mt-2' : 'mt-2',style:(_vm.getPriceColor(_vm.ProductData.style)),on:{"click":function($event){product.readMore =
                                                !product.readMore}}},[(product.readMore)?_c('span',[_vm._v(_vm._s(_vm.translateText("Less info")))]):_c('span',[_vm._v(_vm._s(_vm.translateText("More info"))+" ")])])]):_vm._e()]):_vm._e()]),(product.detail_link)?_c('div',{staticClass:"mt-3"},[_c('button',{style:(_vm.getProductButtonCss(_vm.ProductData.style)),on:{"click":function($event){return _vm.goToURLClicked(product.detail_link)}}},[_vm._v(" Book Now ")])]):_vm._e()])])])])])})],2),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-12"},[_c('p',{style:(_vm.titleStyles)},[_vm._v("Top products for you")])]),_vm._l((_vm.getProducts),function(product,index){return _c('div',{key:index,staticClass:"col-12 col-sm-6 col-md-6 col-lg-4 mb-3"},[_c('div',{staticClass:"card shadow-sm d-flex flex-column h-100 mx-auto position-relative",style:(_vm.getCardBackground(_vm.ProductData.style) +
                                _vm.getCardBorderCSS(_vm.ProductData.style) +
                                _vm.getPagePadding(_vm.ProductData.style) +
                                _vm.getFontFamily(_vm.ProductData.style) +
                                _vm.ListProductMaxWidth(
                                  _vm.isDesktopView
                                    ? _vm.ProductData.productsPerPage
                                    : _vm.ProductData.productsPerPageMobile
                                ))},[_c('div',[(_vm.getProductInfoDetail(_vm.ProductData.useProductVariant, product,'image'))?_c('div',{staticStyle:{"height":"100%"}},[(
                                      _vm.checkFileType(_vm.getProductInfoDetail(_vm.ProductData.useProductVariant, product,'image')) == 'video'
                                    )?_c('div',{style:(_vm.ListImageAspectRatio(_vm.ProductData.style))},[_c('video',{ref:"videoRef",refInFor:true,staticStyle:{"position":"absolute","top":"0","left":"0","bottom":"0","right":"0","height":"100%","width":"100%"},style:(_vm.getImageFit(_vm.ProductData.style) +
                                        _vm.getImageTopBorderCSs(_vm.ProductData.style)),attrs:{"src":`${_vm.getProductInfoDetail(_vm.ProductData.useProductVariant, product,'image')}`,"controls":""}})]):_c('div',{style:(_vm.ListImageAspectRatio(_vm.ProductData.style))},[(_vm.getProductInfoDetail(_vm.ProductData.useProductVariant, product,'image').startsWith('upload'))?_c('LazyImage',{staticStyle:{"position":"absolute","top":"0","left":"0","bottom":"0","right":"0","height":"100%","width":"100%"},style:(_vm.getImageFit(_vm.ProductData.style) +
                                        _vm.getImageTopBorderCSs(_vm.ProductData.style)),attrs:{"src":'/' + _vm.getProductInfoDetail(_vm.ProductData.useProductVariant, product,'image'),"alt":""}}):_c('LazyImage',{staticStyle:{"position":"absolute","top":"0","left":"0","bottom":"0","right":"0","height":"100%","width":"100%"},style:(_vm.getImageFit(_vm.ProductData.style) +
                                        _vm.getImageTopBorderCSs(_vm.ProductData.style)),attrs:{"src":_vm.getProductInfoDetail(_vm.ProductData.useProductVariant, product,'image'),"alt":""}})],1)]):_c('LazyImage',{staticStyle:{"height":"100%","width":"100%"},style:(_vm.getImageTopBorderCSs(_vm.ProductData.style)),attrs:{"src":'/images/image_default_placeholder.png',"alt":""}})],1),_c('div',{staticClass:"mt-2 flex-grow-1",style:(_vm.getPaddingCss(_vm.ProductData.style))},[_c('div',{staticClass:"d-flex flex-column h-100"},[_c('div',{staticClass:"px-3"},[_c('p',{style:(_vm.getTitleStyles(_vm.ProductData.style))},[_vm._v(" "+_vm._s(_vm.getProductInfoDetail(_vm.ProductData.useProductVariant, product,'title'))+" ")]),(
                                        product.rating && _vm.isJudgeMeInstalled
                                      )?_c('div',{staticClass:"Review px-3",style:(_vm.getPriceColor(_vm.ProductData.style)),domProps:{"innerHTML":_vm._s(product.rating)}}):_vm._e(),_c('div',{staticClass:"d-flex align-items-center",style:('justify-content:' +
                                        _vm.ProductData.style.textAlign +
                                        ';')},[(product.price && !_vm.isHidePrice)?_c('p',{staticClass:"mr-2",style:(_vm.getPriceColor(_vm.ProductData.style))},[(
                                            _vm.CurrencySign &&
                                            _vm.currencyPos == 'left'
                                          )?_c('span',{class:_vm.currencySpace ? 'mr-1' : null},[_vm._v(_vm._s(_vm.CurrencySign))]):_vm._e(),_vm._v(_vm._s(_vm.getProductInfoDetail(_vm.ProductData.useProductVariant, product,'price'))),(
                                            _vm.CurrencySign &&
                                            _vm.currencyPos == 'right'
                                          )?_c('span',{class:_vm.currencySpace ? 'ml-1' : null},[_vm._v(_vm._s(_vm.CurrencySign))]):_vm._e()]):_vm._e(),(
                                          product.compare_at_price &&
                                          product.price !==
                                            product.compare_at_price &&
                                          !_vm.isHidePrice
                                        )?_c('p',{style:(_vm.getPriceColor(_vm.ProductData.style))},[_c('s',[(
                                              _vm.CurrencySign &&
                                              _vm.currencyPos == 'left'
                                            )?_c('span',{class:_vm.currencySpace ? 'mr-1' : null},[_vm._v(_vm._s(_vm.CurrencySign))]):_vm._e(),_vm._v(_vm._s(product.compare_at_price)),(
                                              _vm.CurrencySign &&
                                              _vm.currencyPos == 'right'
                                            )?_c('span',{class:_vm.currencySpace ? 'ml-1' : null},[_vm._v(_vm._s(_vm.CurrencySign))]):_vm._e()])]):_vm._e()])]),_c('div',{staticClass:"d-flex flex-column justify-content-between flex-grow-1"},[_c('div',{},[(
                                          !_vm.ProductData.hideDescription &&
                                          !_vm.ProductData.showHeirarchyDesciption
                                        )?_c('div',[_c('div',{staticClass:"d-flex align-items-center mt-1",style:(_vm.limitProductDescription(
                                              _vm.ProductData.limitDescription,
                                              product.readMore
                                            ))},[(_vm.ProductData.useBulletDescription && product.bullet_description
 && product.bullet_description.length
)?_c('div',_vm._l((product.bullet_description),function(bulletPoint,index2){return _c('div',{key:index2},[_c('div',{staticClass:"d-flex align-items-start mt-1",style:(_vm.getBulletStyles(_vm.ProductData.style))},[_c('div',{staticClass:"mr-3"},[_c('i',{staticClass:"fa",class:_vm.ProductData.selectedBulletIcon})]),_c('span',{staticStyle:{"text-align":"start"}},[_vm._v(_vm._s(bulletPoint))])])])}),0):_c('div',{staticClass:"w-100",domProps:{"innerHTML":_vm._s(product.description)}})]),(
                                            _vm.ProductData.limitDescription &&
                                            product.description &&
                                            product.description
                                              .split(' ')
                                              .join('').length > 150
                                          )?_c('div',{},[_c('button',{staticClass:"btn",class:product.readMore ? 'mt-2' : 'mt-2',style:(_vm.getPriceColor(_vm.ProductData.style)),on:{"click":function($event){product.readMore =
                                                !product.readMore}}},[(product.readMore)?_c('span',[_vm._v(_vm._s(_vm.translateText("Less info")))]):_c('span',[_vm._v(_vm._s(_vm.translateText("More info"))+" ")])])]):_vm._e()]):_vm._e()]),(product.detail_link)?_c('div',{staticClass:"mt-3"},[_c('button',{style:(_vm.getProductButtonCss(_vm.ProductData.style)),on:{"click":function($event){return _vm.goToURLClicked(product.detail_link)}}},[_vm._v(" "+_vm._s(_vm.ProductData.buttonText)+" ")])]):_vm._e()])])])])])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }